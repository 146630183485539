import React from 'react';

import CategoryCarousel from '@mshops-components-library/categories-carousel';
import { useRenderContext } from '../../pages/home/context';

const Wrapper = (props) => {
  const { device, isEshops, theme, lowEnd, features, shopType } = useRenderContext();
  const isNewLayout = features?.includes('image-uploader-v2');

  return (
    <CategoryCarousel
      {...props}
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      eshopType={shopType}
      isNewLayout={isNewLayout}
    />
  );
};

export default Wrapper;
