import React from 'react';
import PropTypes from 'prop-types';

import { Card, PolycardContext } from '@polycard/card';

import { useRenderContext } from '../pages/home/context';

const Polycard = ({ item, cta, metrics }) => {
  const { marketplaceInfo, shopType, reqDevice, device } = useRenderContext();
  const { siteId } = marketplaceInfo;
  const polycardContext = item?.result?.polycard_context;
  const newContextValue = { ...polycardContext, type: device === 'desktop' ? 'list-card' : 'grid-card' };
  const trackingId = metrics?.tracking_id;
  const notMLM = siteId !== 'MLM';
  const polycard = item?.result?.polycards?.length > 0 ? item.result.polycards[0] : null;
  const isAffiliate = shopType === 'affiliate';

  const {
    url = '',
    url_fragments: urlFragments = '',
    url_params: urlParams = '',
  } = polycard?.metadata || {};
  const newUrlFragments = urlFragments.replace('%7Btracking_id%7D', trackingId);
  const urlLink = `https://${url}${urlParams}${newUrlFragments}`;
  const deeplink = `meli://${url}?forceInApp=true${urlParams}${newUrlFragments}`;
  const noColumnElements = ['title', 'highlight'];
  const secondColumnElements = ['reviews', 'variations_text'];

  const newPolycardComponents = polycard?.components.map((component) => {
    if (noColumnElements.includes(component?.type)) {
      return component;
    }
    if (secondColumnElements.includes(component?.type)) {
      component.column = 2;
      return component;
    }
    component.column = 1;
    return component;
  });

  const actionLinkComponent = {
    id: 'action_links_with_buttons',
    type: 'action_links',
    action_links: [
      {
        id: 'see_more',
        text: cta,
        url: reqDevice?.webView ? deeplink : urlLink,
        type: 'button',
        hierarchy: 'loud',
      },
    ],
    column: isAffiliate ? 1 : 2,
  };

  return (
    <div className="ui-ms-polycard-container">
      <PolycardContext
        contextValue={newContextValue}
        deviceType={isAffiliate ? 'desktop' : device}
        withoutIcons
        thousandSeparator={notMLM ? '.' : ','}
        decimalSeparator={notMLM ? ',' : '.'}
      >
        {item?.result?.polycards?.map((i) => (
          <Card
            key={i.metadata.id}
            polycard={
              {
                ...i,
                components: isAffiliate ? [...i.components, actionLinkComponent]
                  : [...newPolycardComponents, actionLinkComponent],
                metadata: {
                  ...i.metadata,
                  url_fragments: newUrlFragments,
                },
              }
            }
          />
        ))}
      </PolycardContext>
    </div>
  );
};

Polycard.propTypes = {
  item: PropTypes.shape({
    result: PropTypes.shape({
      polycard_context: PropTypes.shape({
        contract_version: PropTypes.string,
        picture_template: PropTypes.string,
        picture_size_default: PropTypes.string,
        url_prefix: PropTypes.string,
        type: PropTypes.string,
        picture_square_default: PropTypes.string,
        url_fragments_default: PropTypes.string,
        url_params_default: PropTypes.string,
      }),
      polycards: PropTypes.arrayOf(PropTypes.shape({
        metadata: PropTypes.shape({}),
        pictures: PropTypes.shape({}),
        components: PropTypes.arrayOf(PropTypes.shape({})),
      })),
    }),
  }).isRequired,
  cta: PropTypes.string.isRequired,
  metrics: PropTypes.shape({
    tracking_id: PropTypes.string,
    matt_tool: PropTypes.string,
  }).isRequired,
};

export default Polycard;
