export const isOffersCarousel = (componentId) => {
  if (!componentId) return false;

  return /tabbed-carousel_offers|tabbed-carousel_\d+_offers/.test(componentId);
};

export const processUrl = (target, urlFragments = '', itemId) => {
  const [baseUrl, existingFragment] = target.split('#');

  const combinedFragments = [existingFragment, urlFragments.replace('#', '')]
    .filter(Boolean)
    .join('&');

  return {
    url: baseUrl,
    url_fragments: combinedFragments ? `#${combinedFragments}` : '',
    url_params: `?recos_ids=${itemId}`,
  };
};
